import { defineStore } from 'pinia';
import type { Ref } from 'vue';
import type { GlobalStats, GlobalStatsResponseDto } from '@demandio/shared-utils';
/**
 * Use this store to track app-wide features.
 *
 * ie. Opening/closing modals
 */
export const useAppStore = defineStore('app', () => {
  // MARK: Post Click
  const postCopyCouponModalOpen = ref(false);
  const postClickProductModalOpen = ref(false);

  const allCouponsModalOpen = ref(false);
  const activateRewardModalOpen = ref(false);
  const allSUCodesModalOpen = ref(false);
  const copySUCodeModalOpen = ref(false);
  const mobileMenuOpen = ref(false);
  const searchIsOpen = ref(false);
  const reviewModalOpen = ref(false);

  const isNavSticky = ref(false);
  const isSubNavVisible = ref(false);

  const featureFlags = ref<string[]>([]);

  const showAlert = ref(false);
  const alertMessage = ref('');

  const globalStats = ref<GlobalStats>();
  const totalActiveUsers = ref(17_598); // ToDo: make sure this is being used and see if we can Data Drive this

  const [showNotEligibleBanner, toggleNotEligibleBanner] = useToggle(true);

  // TODO: Persist dismissed banner, will do in a follow up
  // https://prazdevs.github.io/pinia-plugin-persistedstate/frameworks/nuxt-3.html
  // We could use vueuse useLocalStorage for an easy persistance
  const dismissedBanner = ref('');

  /* MARK: Toaster */
  const hideToaster = ref(false);
  const toastStack = ref<Map<number, any>>(new Map());
  function removeToast(id: number) {
    toastStack.value.delete(id);
  }

  const redirect = ref('');

  function openModal(modalToOpen: Ref<boolean>) {
    modalToOpen.value = true;
  }
  function closeModal(modalToClose: Ref<boolean>) {
    modalToClose.value = false;
  }

  const openCopiedCodeModal = () => openModal(postCopyCouponModalOpen);
  const closeCopiedCodeModal = () => closeModal(postCopyCouponModalOpen);

  const openPostClickProductModal = () => openModal(postClickProductModalOpen);
  const closePostClickProductModal = () => closeModal(postClickProductModalOpen);

  const openCopiedSUCodeModal = () => openModal(copySUCodeModalOpen);
  const closeCopiedSUCodeModal = () => closeModal(copySUCodeModalOpen);

  const openAllCouponsModal = () => openModal(allCouponsModalOpen);
  const closeAllCouponsModal = () => closeModal(allCouponsModalOpen);

  const openActivateRewardModal = () => openModal(activateRewardModalOpen);
  const closeActivateRewardModal = () => closeModal(activateRewardModalOpen);

  const openAllSUCodeModal = () => openModal(allSUCodesModalOpen);
  const closeAllSUCodeModal = () => closeModal(allSUCodesModalOpen);

  const openMobileMenu = () => openModal(mobileMenuOpen);
  const closeMobileMenu = () => closeModal(mobileMenuOpen);

  const openSearch = () => openModal(searchIsOpen);
  const closeSearch = () => closeModal(searchIsOpen);

  const openReviewModal = () => openModal(reviewModalOpen);
  const closeReviewModal = () => closeModal(reviewModalOpen);

  const confirmDeleteModalOpen = ref(false);
  const openConfirmDelete = () => openModal(confirmDeleteModalOpen);
  const closeConfirmDelete = () => closeModal(confirmDeleteModalOpen);

  const reviewListModalOpen = ref(false);
  const openReviewList = () => openModal(reviewListModalOpen);
  const closeReviewList = () => closeModal(reviewListModalOpen);

  const authModalOpen = ref(false);
  const openAuthModal = () => openModal(authModalOpen);
  const closeAuthModal = () => closeModal(authModalOpen);

  const couponBoxPopUnderOpen = ref(false);
  const screenshotId = ref(0);
  const screenshotModalWasOpened = ref(false);

  const openCouponBoxPopUnder = (id?: number) => {
    openModal(couponBoxPopUnderOpen);

    if (id) {
      screenshotId.value = id;
    }
  };

  const closeCouponBoxPopUnder = () => {
    closeModal(couponBoxPopUnderOpen);
    screenshotId.value = 0;
  };

  /*
    Converting this to a computed with an override
    because we can have multiple modals open at once
  */
  const _allModals = reactive([
    activateRewardModalOpen,
    allCouponsModalOpen,
    allSUCodesModalOpen,
    authModalOpen,
    confirmDeleteModalOpen,
    copySUCodeModalOpen,
    couponBoxPopUnderOpen,
    mobileMenuOpen,
    postClickProductModalOpen,
    postCopyCouponModalOpen,
    reviewListModalOpen,
    reviewModalOpen,
    searchIsOpen,
  ]);
  const modalOpen = computed(() => _allModals.some(modal => modal.value));
  function closeAllModals() {
    _allModals.forEach(modal => modal.value = false);
  }

  const subNavVisibility = (status: boolean) => {
    isSubNavVisible.value = status;
  };

  const hasFeatureFlag = (flag: string) => {
    return featureFlags.value.includes(flag);
  };

  const setShowAlert = (message: string) => {
    alertMessage.value = message;
    showAlert.value = true;
  };

  const closeAlert = () => {
    showAlert.value = false;
    alertMessage.value = '';
  };

  const dismissBanner = (banner: string) => {
    dismissedBanner.value = banner;
  };

  const fetchGlobalStats = async () => {
    const context = useNuxtApp();
    const result = (await context.$http.get(
      'summary/sitewide-stats',
      {},
      context.$http.API_VERSIONS.v1,
    )) as GlobalStatsResponseDto;

    globalStats.value = result.sitewideStats;

    return result.sitewideStats.counts;
  };

  const onRedirect = (val: string) => {
    redirect.value = val;
  };

  return {
    fetchGlobalStats,
    dismissBanner,
    dismissedBanner,
    alertMessage,
    activateRewardModalOpen,
    allCouponsModalOpen,
    allSUCodesModalOpen,
    closeActivateRewardModal,
    closeAlert,
    closeAllCouponsModal,
    closeAllSUCodeModal,
    closeConfirmDelete,
    closeCopiedCodeModal,
    closeCopiedSUCodeModal,
    closeMobileMenu,
    closeReviewList,
    closeReviewModal,
    closeSearch,
    confirmDeleteModalOpen,
    copySUCodeModalOpen,
    featureFlags,
    hasFeatureFlag,
    isNavSticky,
    mobileMenuOpen,
    modalOpen,
    openActivateRewardModal,
    openAllCouponsModal,
    openAllSUCodeModal,
    openConfirmDelete,
    openCopiedCodeModal,
    openCopiedSUCodeModal,
    openMobileMenu,
    openReviewList,
    openReviewModal,
    openSearch,
    postCopyCouponModalOpen,
    reviewListModalOpen,
    reviewModalOpen,
    searchIsOpen,
    setShowAlert,
    showAlert,
    isSubNavVisible,
    subNavVisibility,
    redirect,
    onRedirect,
    showNotEligibleBanner,
    toggleNotEligibleBanner,
    hideToaster,
    toastStack,
    removeToast,
    totalActiveUsers,
    authModalOpen,
    openAuthModal,
    closeAuthModal,
    postClickProductModalOpen,
    openPostClickProductModal,
    closePostClickProductModal,
    closeAllModals,
    couponBoxPopUnderOpen,
    openCouponBoxPopUnder,
    closeCouponBoxPopUnder,
    screenshotId,
    screenshotModalWasOpened,
  };
});
